import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { hydrate, render } from "react-dom";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { Helmet, HelmetProvider } from 'react-helmet-async';
const rootElement = document.getElementById("root");
const helmetContext = {};
if (rootElement.hasChildNodes()) {
  hydrate(
    <HelmetProvider context={helmetContext}>
  <App />
  </HelmetProvider>
  , rootElement);
} else {
  render(
    <HelmetProvider context={helmetContext}>
    <App />
    </HelmetProvider>
  , rootElement);
}


reportWebVitals();